import React, { useState } from "react"
import {
  Navigation,
  Footer,
  Hero,
  Breadcrumb,
} from "../../../components/Marketing"
import { Seo } from "../../../components/Seo/Seo"
import { Haikus } from "../../../components/article/Haikus"

type Props = {
  pageContext: {
    author: {
      name: string
      bio: string | null
      source: string | null
      source_name: string | null
      birthdate: string | null
      deathdate: string | null
      country: string | null
    }
    haikus: {
      content: string
      id: string
    }[]
  }
}

export default ({ pageContext }: Props) => {
  const author = pageContext.author

  const breadcrumbs = [
    { label: "Recueils de haïkus", url: "/recueils/" },
    { label: "Par auteurs", url: "/recueils/auteurs/" },
    { label: author.name, url: "/recueils/auteurs/" + author.name + "/" },
  ]

  return (
    <>
      <Seo
        title={`${author.name}, tous ses haikus avec des exemples`}
        description={`La page des recueils de haïkus de ${author.name} regroupe l'ensemble des haïkus de l'auteur.`}
        breadcrumbs={breadcrumbs}
      />
      <Navigation />
      <Hero
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Les haïkus de{" "}
              <span className="text-green-600">{author.name}</span>
            </>
          ),
        }}
        description={{
          as: "div",
          value: (
            <>
              <h3 className="text-sm font-bold text-gray-800 uppercase">
                Biographie
              </h3>
              {pageContext.author.bio || (
                <p>
                  <span className="font-medium text-gray-900">
                    {pageContext.author.name}
                  </span>{" "}
                  n'a pas encore de biographie dédiée. Cependant, nous faisons
                  notre maximum pour enrichir ses pages afin de rendre hommage
                  aux auteurs.
                </p>
              )}
              {pageContext.author.bio &&
                pageContext.author.source &&
                pageContext.author.source_name && (
                  <div className="flex py-1 pl-2 mt-4 text-sm border-l-2 border-gray-300">
                    <span>Source: </span>
                    <a
                      rel="nofollow"
                      className="ml-2 underline"
                      target="blank"
                      href={pageContext.author.source}
                    >
                      {pageContext.author.source_name}
                    </a>
                  </div>
                )}

              <h3 className="mt-10 text-sm font-bold text-gray-800 uppercase">
                Date de naissance et de décès
              </h3>
              <p>
                {author.birthdate} - {author.deathdate}
              </p>

              <h3 className="mt-10 text-sm font-bold text-gray-800 uppercase">
                Nationalité
              </h3>
              <p>{author.country}</p>
            </>
          ),
        }}
      />
      
      <Haikus
        haikus={pageContext.haikus.map(haiku => ({
          content: haiku.content,
          author: {
            fullName: author.name,
          },
        }))}
      />

      <Footer />
    </>
  )
}
